import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export function FaqFr() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Foire aux questions de Pneusfrance.fr</title>
      </Helmet>

      <h1 className="green mt-auto">Questions fréquemment posées</h1>
      <p>
        Pneusfrance.fr souhaite vous guider au mieux dans l'achat des pneus
        qui conviennent à votre voiture et à votre situation.
        <br />
        Nous avons rassemblé ci-dessous les questions les plus fréquemment
        posées par nos clients.
        <br />
        Si vous n'y trouvez pas la réponse, nous sommes là pour vous aider.
      </p>

      <h2>Questions sur la commande</h2>

      <h3>Comment commander mes pneus ?</h3>
      <p>
        Suivez les étapes suivantes :
        <ol>
          <li>
            <strong>Relever la taille des pneus</strong> des pneus actuels.{" "}
            <br />
            Notre{" "}
            <Link to="/tyre-guide" className="link">
              guide du pneu
            </Link>{" "}
            vous aidera.
          </li>
          <li>
            Utilisez la <strong>recherche avancée</strong> sur la{" "}
            <Link to="/" className="link">
              page d'accueil
            </Link>
            .
          </li>
          <li>
            <strong>Choisissez le pneu idéal</strong> qui répond à toutes vos
            préférences. <br />
            Utilisez la <strong>fonction filtre</strong>.
          </li>
          <li>Choisissez la quantité et le mode de livraison et commandez.</li>
        </ol>
      </p>
      <h3>
        Puis-je modifier mon adresse de livraison après avoir passé ma commande
        ?
      </h3>
      <p>
        Toutes les commandes sont créées chez Pneusfrance.fr ! de manière
        entièrement automatique.
        <br />
        Nos partenaires de transport viennent chercher les commandes plusieurs
        fois par jour.
        <br />
        Il n'est donc pas possible de modifier l'adresse de livraison après
        avoir passé la commande.
      </p>
      <h3>Puis-je commander uniquement par Internet ?</h3>
      <p>
        Pneusfrance.fr! offre ses produits <strong>uniquement</strong> en
        ligne.
        <br />
        Vous pouvez donc commander de <strong>n'importe où</strong>.<br />
        Vous payez directement <strong>via la boutique en ligne</strong> et
        cette méthode est <strong>sécurisée</strong>.<br />
        Ce n'est que de cette manière que nous pouvons maintenir nos coûts à un
        niveau bas et vous offrir <strong>les meilleurs prix</strong>.<br />
      </p>
      <h3>Dois-je payer d'autres frais en plus du prix des produits ??</h3>
      <p>
        Nos prix comprennent toujours tous les coûts.
        <br />
        Pas de surprises après.
      </p>
      <h3>Où puis-je poser des questions sur ma commande ?</h3>
      <p>
        Vous pouvez contacter l'équipe de Pneusfrance.fr via{" "}
        <Link to="/contact" className="link">
          le formulaire de contact
        </Link>{" "}
        ou via{" "}
        <a href="mailto:info@pneusfrance.fr" className="link">
          e-mail
        </a>
        .<br />
        NVeuillez indiquer votre <strong>numéro de commande</strong> dans chaque
        communication.
      </p>
      <h3>J'ai passé ma commande, que faire maintenant ?</h3>
      <p>
        Vous recevrez un courriel avec l'aperçu de votre commande.
        <br />
        Les pneus sont livrés à votre domicile ou chez le partenaire de montage
        choisi dans un délai maximum de 3 jours ouvrables.
        <br />
        Bien sûr, nous restons disponibles via{" "}
        <a href="mailto:info@pneusfrance.fr" className="link">
          e-mail
        </a>{" "}
        ou via
        <Link to="/contact" className="link">
          le formulaire de contact
        </Link>
        .<br />
        Ayez toujours votre <strong>numéro de commande</strong> à portée de
        main.
      </p>
      <h3>Quel est le délai de livraison de ma commande ?</h3>
      <p>
        Nous travaillons avec différents services de messagerie, c'est pourquoi
        le délai de livraison varie. Notre objectif est de livrer votre commande
        dans les 3 jours ouvrables.
      </p>

      <h2>Questions sur le montage</h2>
      <h3>Où puis-je faire monter les pneus ?</h3>
      <p>
        Vous pouvez le faire vous-même ou choisir un partenaire de montage à
        proximité. Vous trouverez un aperçu{" "}
        <Link to="/partners" className="link">
          ici
        </Link>
        .<br />
        Vous indiquez le fournisseur de services que vous avez choisi lors du
        processus de commande.
      </p>
      <h3>
        Comment prendre rendez-vous pour le montage des pneus que j'ai commandés
        ?
      </h3>
      <p>
        Le partenaire de montage sélectionné reçoit un détail de votre commande
        et de la livraison prévue. <br />
        Voulez-vous que les pneus soient montés là ?<br />
        <strong>
          Contactez-le alors et organisez votre propre rendez-vous pour le
          montage.
        </strong>
        <br />
      </p>
      <h3>Qui est responsable de la vérification de la commande livrée ?</h3>
      <p>
        Vous êtes toujours responsable du contrôle de la livraison, même si elle
        a lieu chez notre partenaire de montage.
        <br />
        Le contrôle est toujours effectué avant le montage.
        <br />
        Les pneus montés ne seront pas repris.
        <br />
        Le droit de rétractation n'est alors plus applicable.
      </p>

      <h2>Questions sur notre stock</h2>
      <h3>Tous les produits sont-ils en stock sur le site ?</h3>
      <p>
        Oui, notre stock complet est affiché sur notre site web. Pour chaque
        produit, la quantité restante est indiquée à partir de 8 pièces.
      </p>
      <h3>Que faire si je ne trouve pas le pneu souhaité ?</h3>
      <p>
        Si le pneu que vous souhaitez n'est pas dans notre boutique en ligne,
        nous ne l'avons pas en stock.
        <br />
        Dès que les pneus seront de nouveau en stock, nous mettrons le site web
        à jour.
        <br />
        N'hésitez pas à contacter l'équipe de Pneusfrance.fr pour toute
        question concernant un produit qui n'est pas en stock.
        <br />
        Nous vous aiderons dans votre recherche d'une alternative valable.
      </p>
    </>
  );
}
