import React, { useState } from "react";
import logoNl from "../img/PneusFranceLogoTransparant.png";
import shoppingCart from "../img/shopping-cart.png";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import { LANGUAGE } from "../App";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

export function Header({
  language,
  setLanguage,
  cart,
}: {
  language: string;
  setLanguage: (lang: LANGUAGE) => void;
  cart: {
    id: string;
    name: string;
    count: number;
    price: number;
  }[];
}) {
  const [showMobile, setShowMobile] = useState(false);
  const { t, i18n } = useTranslation("header");
  const [openLanguage, setOpenLanguage] = useState(false);

  async function changeLanguage() {
    if (language === LANGUAGE.NL) {
      setLanguage(LANGUAGE.FR);
      await i18n.changeLanguage(LANGUAGE.FR);
    } else {
      setLanguage(LANGUAGE.NL);
      await i18n.changeLanguage(LANGUAGE.NL);
    }
  }

  return (
    <>
      <div className="greenHeader flex items-center justify-flex-end">
        <NavLink
          className="link"
          activeClassName="active"
          to="/faq"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("faq")}
        </NavLink>
        <a
          className="link"
          href="https://fr.trustpilot.com/review/pneusfrance.fr"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("reviews")}
        </a>
        <NavLink
          className="link"
          activeClassName="active"
          to="/partner-login"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("become_partner")}
        </NavLink>
      </div>
      <div className="blackHeader flex items-center justify-flex-end">
        <Link to="/">
          <img src={logoNl} alt="Logo" />
        </Link>
        <div className="blackHeaderLinks">
          <div className={`navitems ${showMobile ? "mobile" : ""}`}>
            <NavLink
              className="navitem"
              exact={true}
              activeClassName="active"
              to="/"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("home")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/marques-pneus"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("our_brands")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/partners"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("montage")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/tyre-guide"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("tyre-guide")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/contact"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("contact")}
            </NavLink>
          </div>
          <Link
            to="/cart"
            onClick={() => {
              setShowMobile(false);
            }}
          >
            <img src={shoppingCart} alt="Shopping cart" />
            <div className="cart-count">
              {cart.map(item => item.count).reduce((a, b) => a + b, 0)}
            </div>
          </Link>
          <div
            className="hamburger"
            onClick={() => {
              setShowMobile(!showMobile);
            }}
          >
            {showMobile ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
