import React from "react";

export function Returns() {

  return (
    <div className="containerTransparant w-45">
      <h1 className="green">Retourner à</h1>
      <h2>Comment puis-je exercer mon droit de rétractation ?</h2>
      <div>
        <p>Dans un délai de 14 jours civils, vous avez le droit de vous retirer du contrat sans donner de raisons. Le délai de rétractation expire 1
          4 jours calendaires après le jour où vous ou un tiers autre que le transporteur et indiqué par vous prend physiquement possession des biens.
          Pour exercer le droit de rétractation, vous devez nous informer de votre volonté de vous retirer du contrat par une déclaration non
          équivoque (par exemple, par écrit, par courrier ou par e-mail). Vous pouvez
          utiliser <a href="./files/modèle de formulaire de rétractation.pdf" target="_blank" className="link" download>le modèle de formulaire de rétractation (PDF)</a> que nous
          vous avons envoyé à cette fin, mais vous n'êtes pas obligé de le faire. Pour respecter le délai de rétractation, il suffit d'envoyer votre communication concernant votre exercice du droit de rétractation avant l'expiration du délai de rétractation. Effets de la rétractation Si vous vous rétractez du contrat, vous recevrez tous les paiements que vous avez effectués jusqu'à ce moment-là, y compris les frais de livraison (à l'exception de tous les frais supplémentaires résultant de
          votre choix d'un mode de livraison autre que la livraison standard la moins chère que nous proposons) sans délai et, en tout état de cause, au plus tard 14 jours calendaires après que nous ayons été informés de votre décision de vous retirer de l'offre.
          accord de notre part. Nous vous rembourserons en utilisant le même moyen de paiement que celui que vous avez utilisé pour l'achat de la carte de crédit.
          la transaction initiale, sauf si vous avez expressément convenu du contraire ; en tout état de cause
          vous ne serez pas facturé pour ce remboursement. Nous pouvons retenir un remboursement
          jusqu'à ce que nous ayons reçu les marchandises en retour ou que vous ayez démontré que vous avez renvoyé les marchandises, selon la première éventualité.
          selon la première éventualité. Vous devez renvoyer les marchandises immédiatement, mais en tout état de cause au plus tard le
          14 jours civils à compter du jour où vous nous communiquez votre décision de rétractation du contrat, pour
          nous retourner ou nous remettre les marchandises. Vous êtes dans les temps si vous renvoyez les marchandises avant la période de
          Le délai de 14 jours civils a expiré. Les frais directs de renvoi des marchandises sont à votre charge.
          Vous n'êtes responsable que de la dépréciation des marchandises résultant de l'utilisation de
          les biens au-delà de ce qui est nécessaire pour établir la nature, les caractéristiques et le fonctionnement des biens.
        </p>
      </div>
    </div>
  );
}
