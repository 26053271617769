import React from "react";
import { SearchBarSmall } from "./components/SearchBarSmall";
import { Brand } from "./api/BrandApi";
import { SearchOptions } from "./api/TyreApi";
import { ContactSheet } from "./components/ContactSheet";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";

export function Contact({
  brands,
  options,
}: {
  brands: Brand[];
  options: SearchOptions;
}) {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contactez-nous | PneusFrance.fr</title>
      </Helmet>
      <SearchBarSmall brands={brands} options={options} />
      <h1 className="green w-80 m-auto">Contactez nous</h1>
      <div className="flex containerTransparant contact">
        <p>
          L'équipe de PneusFrance.fr est là pour vous aider dans votre choix.
          Vous avez des questions concernant votre commande, le fonctionnement du site ou autre chose ?
          Envoyez un courriel à <a href="mailto:info@pneusfrance.fr" className="link">info@pneusfrance.fr</a> ou posez
          votre question via le formulaire de contact. Nous répondrons dès que possible.
        </p>
        <ContactSheet />
      </div>
    </>
  );
}
