import React from "react";
import { Helmet } from "react-helmet";
import { TermsFr } from "./components/TermsFr";

export function Terms() {
  return (
    <div className="containerTransparant">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conditions générales | Pneusfrance.fr
        </title>
      </Helmet>
      <TermsFr />
    </div>
  );
}
