import React from "react";
import { FaqFr } from "./components/FaqFr";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function Faq() {
  return (
    <div className="containerTransparant">
      <Helmet>
        <title>Foire aux questions | Pneusfrance.fr</title>
        <meta name="title" content="Foire aux questions | Pneusfrance.fr" />
        <meta name="description" content="Pneus de voiture bon marché online et livraison rapide ? Lisez comment cela fonctionne sur Pneusfrance.fr."/>
        </Helmet>
        <FaqFr />
    </div>
  );
}
