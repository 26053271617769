import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./Success.css";
import "./Checkout.css";
import { getOrder, Order } from "./api/OrderApi";
import { useEffectOnce } from "react-use";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Helmet } from "react-helmet";

export function Success({ deleteCart }: { deleteCart: () => void }) {

  // @ts-ignore
  let { orderId } = useParams();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const sendDataToGTM = useGTMDispatch();

  const handlePurchase = () => {
    sendDataToGTM({ event: "purchase",
      ecommerce: {
        transaction_id: order?.orderId,
        value: order?.price,
        shipping: order?.transport,
        transaction_cost: order?.transactionCost,
        currency: "EUR",
        items: order?.tyres.map((item) => (
          {
            item_id: item.tyreId,
            quantity: item.amount,
            price: item.price,
            currency: "EUR"
          }
        )),
      },
    });
  };

  useEffectOnce(() => {
    getOrder(orderId).then(result => {
      setOrder(result);

      if (result && result.orderStatus === "Betaald") {
        deleteCart();
        handlePurchase();
      }

      setLoading(false);
    });
  });

  if (loading) {
    return (
      <div className="order-container">
        <>
          <div className="flex justify-center">
            <h1>Récupérer la commande...</h1>
          </div>
          <div className="flex justify-center text-center flex-column items-center">
            <p>
              Nous venons chercher votre commande. Veuillez être patient...
            </p>
          </div>
        </>
      </div>
    );
  }

  return (
    <div className="order-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Commande effectuée | Pneusfrance.fr!</title>
      </Helmet>
      {order && order.orderStatus === "Betaald" ? (
        <>
          <div className="flex justify-center">
            <h1>Commande effectuée</h1>
          </div>
          <div className="flex justify-center text-center flex-column items-center">
            <p>
              Votre commande a été correctement traitée. Veuillez vérifier votre boîte aux lettres pour un
              e-mail de confirmation de votre commande. Si vous avez des questions, n'hésitez pas
              à <a href="/contact" className="link">contacter</a> notre service d'assistance.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center">
            <h1>Commande interrompue</h1>
          </div>
          <div className="flex justify-center text-center">
            <p>
              Oups, quelque chose a mal tourné. Veuillez réessayer ou nous <a href="/contact" className="link">contacter</a>.
            </p>
          </div>
        </>
      )}
    </div>
  );
}
