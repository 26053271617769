import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function DeliveryOptions() {
  const { t } = useTranslation("delivery-options");

  return (
    <div className="containerTransparant w-50">
      <Helmet>
        {getAlternateCanonical()}
        <link rel="canonical" href={window.location.href} />
        <title>
          Acheter des pneus de voiture online avec montage | Pneusfrance.fr
        </title>
        <meta name="description" content="Autobanden montage dichtbij en goedkoop? Weet waarvoor u betaalt en koop uw autobanden voordelig en laat ze eenvoudig monteren en balanceren bij één van onze montagepartners bij u in de buurt." />
        <meta name="keywords" content="Autobanden, montage, online banden, banden online, waar monteren" />
      </Helmet>
      <h1 className="green">{t("title")}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: t("delivery-options"),
        }}
      ></div>
    </div>
  );
}
