import React from "react";
import { Link } from "react-router-dom";

export function TermsFr() {
  return (
    <>
      <h1 className="green mt-auto">Conditions générales</h1>
      <h3>Article 1. Champ d’application </h3>
      <p>
        Les présentes conditions générales s'appliquent à tous les contrats
        conclus entre le client (<b>Client</b>) et BEL-IDE HOLDING BV, dont le
        siège social est situé Restert 66A, 9320 Aalst, TVA BE0692.975.423 (
        <b>Vendeur</b>).
        <br />
        Un contrat (<b>Contrat</b>) est conclu entre le Client et le Vendeur dès
        que le Client a terminé la procédure de commande via{" "}
        <Link to="/" className="link">
          www.pneusfrance.fr
        </Link>{" "}
        (<b>Boutique en Ligne</b>) et a reçu un e-mail de confirmation
        conformément à l'article 3 des présentes conditions générales. Les
        présentes conditions générales prévalent sur toutes les autres
        conditions (générales) qui peuvent s'appliquer au Contrat ou entre les
        parties.
      </p>

      <h3>Article 2. Prix</h3>
      <p>
        <b>2.1.</b> Les prix mentionnés dans la Boutique en Ligne s'entendent
        TVA comprise, hors frais d'expédition, de montage et d'installation.
        <br />
        <b>2.2.</b> Les frais d'expédition sont ajoutés à la commande dans
        l'aperçu du panier et dépendent (i) du prix total de la commande et (ii)
        de l'adresse de livraison (conformément à l’article 3).
      </p>
      <h3>Article 3. Procédure de commande et mode de paiement</h3>
      <p>
        BLes commandes sont passées via www.pneusfrance.fr et se font selon la
        procédure suivante:
        <ul>
          <li>sélection de marchandises dans un panier virtuel ;</li>
          <li>
            si la sélection est terminée et que le Client décide de procéder au
            paiement (en cliquant sur le bouton " <i>procéder au paiement</i> "
            ou " <i>passer à la caisse</i> "), un aperçu du panier apparaît,
            indiquant le prix total des biens, les modes de livraison
            (enlèvement ou livraison) et les frais d'expédition, de sorte que
            d'éventuels frais incorrects puissent être détectés et corrigés
            avant que la commande ne soit définitivement passée par le Client ;
          </li>
          <li>
            la saisie de l'adresse et des données personnelles du Client ;
          </li>
          <li>
            l'obligation de lire les conditions générales (via une fenêtre
            pop-up qui apparaît automatiquement) et l'acceptation des conditions
            générales par le Client ;{" "}
          </li>
          <li>
            sélectionner le mode de paiement souhaité (paiement direct via
            Paypal ou par carte de débit) (ce qui ne peut être fait avant
            l'acceptation des conditions générales){" "}
          </li>
          <li>
            le paiement via le mode de paiement sélectionné par le Client ;
          </li>
          <li>
            réception d'un e-mail du Vendeur avec un résumé de la commande ;
          </li>
          <li>
            la réception d'un email de confirmation de la part du Vendeur, après
            que le Vendeur ait reçu le paiement.
          </li>
        </ul>
      </p>
      <h3>Article 4. Délais de livraison</h3>
      <p>
        Sauf indication contraire dans la Boutique en Ligne ou dans l'e-mail de
        confirmation, le délai de livraison est de 30 jours. Celle-ci commence
        le jour suivant la réception de l'e-mail de confirmation. Le délai de
        livraison est en tout cas prolongé de la période pendant laquelle
        l'exécution est impossible pour cause de force majeure de la part du
        Vendeur (y compris, entre autres, la fermeture annuelle des fournisseurs
        du Vendeur). Le Client accepte expressément que le délai de livraison
        est indicatif et ne peut donc donner lieu au paiement d'une quelconque
        indemnité par le Vendeur, sans préjudice de l'article 10.
      </p>
      <h3>Article 5. Levering </h3>
      <p>
        <b>5.1.</b> Le Client peut choisir de faire livrer les biens à l'adresse
        de livraison de son choix ou dans un centre de montage.
        <br />
        <b>5.2.</b> La livraison est effectuée par l'un des transporteurs
        partenaires du Vendeur et a lieu au lieu et à la date convenus par écrit
        (après achèvement de la procédure de commande conformément à l'article
        3) entre le Client et le transporteur partenaire qui en prend
        l'initiative.
        <br />
        <b>5.3.</b> Les biens commandés dans un même panier seront en principe
        livrés en une seule fois, à la date de disponibilité de l'ensemble des
        biens commandés. En cas d'expédition fractionnée, les frais d'expédition
        ne dépasseront jamais le montant indiqué dans l'aperçu du panier
        d'achat, conformément à l'article 3.
        <br />
        <b>5.4.</b> La livraison des marchandises est une obligation de moyen du
        Vendeur. Le Client est tenu de s'assurer : (i) que le lieu de livraison
        est facilement accessible et que l'espace de stationnement est
        suffisant, (ii) que le lieu de livraison est approprié et sûr, et (iii)
        que le Vendeur et/ou ses employés ont un accès illimité au lieu de
        livraison. Le Client garantit au Vendeur sa présence, ou celle d'un
        représentant autorisé, pour prendre livraison des biens commandés. Si le
        lieu de livraison est inaccessible, inadapté et/ou dangereux, ou si le
        Client ou son représentant n'est pas présent pour la livraison des
        biens, le Vendeur est en droit de refuser la livraison. Dans ce cas,
        tous les frais déjà engagés ou restant à engager sont à la charge du
        Client et ce dernier est responsable de la récupération des biens.
        <br />
        <b>5.5.</b> Si le Client choisit de faire livrer la commande dans un
        centre de montage, il s'engage, sauf cas de force majeure, (i) à retirer
        la commande dans les 30 jours suivant la date de livraison et (ii) à
        faire monter les biens par le centre de montage concerné. Les
        dispositions concrètes concernant le montage (y compris le prix) sont
        prises directement entre le Client et le centre de montage. Le Vendeur
        n'est pas responsable de la réception, du stockage, du démontage, du
        montage, des services d'équilibrage, du retard dans le montage, etc. qui
        ont lieu sous la responsabilité entière et exclusive du centre de
        montage.
        <br />
        Dans le cas où le Client ne retire pas les biens à temps, le Vendeur se
        réserve le droit de :<br />
        (i) dissoudre le Contrat aux frais du Client sans aucune indemnité ;
        <br />
        (ii) facturer une indemnité de 5 euros par jour de retard, calculée à
        partir de la date de livraison, à titre de frais de stockage. <br />
        Dans le cas où le Client refuse de faire monter les biens par le centre
        de montage où les biens ont été livrés, le centre de montage est en
        droit de réclamer une compensation supplémentaire au Client.
        <br />
        <b>5.6.</b> La livraison par le Vendeur s'entend hors montage.
      </p>

      <h3>Article 6. Conservation du titre</h3>
      <p>
        Le transfert de propriété des marchandises n'a lieu qu'après paiement
        intégral du prix et des frais éventuels.
      </p>

      <h3>Article 7. Transfert de risque</h3>
      <p>
        Nonobstant l'article 4, le risque de dommage ou de perte des biens est
        transféré au Client lors de la livraison par le Vendeur ou son
        partenaire-transporteur désigné.
      </p>

      <h3>Article 8. Droit de retrait</h3>
      <p>
        <b>8.1.</b> Conformément à l'article VI.47 du code de droit économique,
        le Client a le droit de se rétracter du Contrat sans donner de raisons
        dans un délai de 14 jours calendaires à compter du jour suivant la
        possession physique de tous les biens.
        <br />
        <b>8.2.</b> Le Client n'a aucun droit de rétractation pour les biens
        fabriqués selon ses spécifications particulières.
        <br />
        <b>8.3.</b> Pour exercer son droit de rétractation, le Client doit
        envoyer sa décision par lettre recommandée au Vendeur dans le délai
        prévu à l'article 8.1. Il peut soit envoyer le formulaire de
        rétractation signé et disponible sur le site web, soit écrire une lettre
        pour communiquer sa décision. Si le droit de rétractation est exercé, le
        Client doit renvoyer les biens à l'adresse indiquée par le Vendeur, non
        utilisés, non montés, non endommagés et dans leur emballage d'origine,
        dans les 30 jours suivant l'envoi de sa décision de se rétracter. Les
        frais d'expédition sont à la charge du Client. Le Vendeur confie le
        transport de la marchandise à un transporteur partenaire. Il doit
        restituer toutes les sommes versées par le Client, moins les frais
        d'expédition, dans les 30 jours suivant la réception des biens par le
        Vendeur. Ce remboursement est effectué en utilisant le même mode de
        paiement que celle choisie par le Client, conformément à l'article 3.
        <br />
      </p>

      <h3>Article 9. Plaintes - Garanties</h3>
      <p>
        <b>9.1.</b> Le Client est tenu d'inspecter les biens au moment de la
        livraison afin de détecter les défauts visibles et de noter tout défaut
        visible sur le bon de livraison. Le Client doit, dans les meilleurs
        délais, notifier à{" "}
        <a href="mailto:info@pneusfrance.fr" className="link">
          info@pneusfrance.fr
        </a>{" "}
        toute remarque concernant les défauts visibles, par courrier
        électronique, accompagnée de toutes les informations (description,
        photo, etc.) prouvant le défaut visible, faute de quoi l'état des biens
        sera considéré comme accepté par le Client. <br />
        <b>9.2.</b> Le présent article est sans préjudice du droit de
        rétractation conformément à l'article 8 et de la garantie légale de 2
        ans.
      </p>

      <h3>Article 10. Retard de livraison</h3>
      <p>
        Si le Vendeur dépasse le délai de livraison contractuel de plus de 2
        semaines, le Client doit mettre le Vendeur en demeure par lettre
        recommandée, le Vendeur disposant alors d'un dernier délai de 15 jours
        ouvrables pour livrer. Si la livraison n'a pas lieu dans le délai
        susmentionné, le Client a la possibilité de dissoudre le Contrat. Pour
        ce faire, il doit envoyer une lettre recommandée au Vendeur dans les 30
        jours suivant l'expiration du délai de 15 jours ouvrables susmentionné.
        Dans ce cas, le prix payé par le Client est remboursé dans les 30 jours.
        Cela n'affecte pas le droit du Client de demander une indemnité pour les
        dommages réellement subis.
      </p>

      <h3>Article 11. Force majeure</h3>
      <p>
        <b>11.1.</b> Chaque partie pourra invoquer la force majeure et les
        mesures et/ou obligations découlant de nouvelles réglementations pour
        annuler, suspendre ou retarder l'exécution de tout ou partie de ses
        obligations (y compris celles découlant des présentes conditions
        générales) sans préavis ni indemnité, même celles préalablement
        convenues entre les parties.
        <br />
        <b>11.2.</b> Sont conventionnellement considérés comme cas de force
        majeure, qu'ils surviennent chez le Vendeur, ses sous-traitants ou ses
        fournisseurs, la guerre, les émeutes, les manifestations, les blocus,
        les grèves, les lock-out, les bris de machine, les incendies, les
        explosions, les troubles sociaux, les épidémies, les intempéries,
        l'insuffisance d'approvisionnement en énergie, combustibles, matières
        premières ou consommables, l'interruption des moyens de transport, la
        faillite ou l'insolvabilité ou tout événement exceptionnel empêchant ou
        entravant la fabrication, le transport ou la vente des marchandises.
      </p>

      <h3>Article 12. Responsabilité civile</h3>
      <p>
        La responsabilité du Vendeur ne peut être exclue qu'en cas de fraude,
        d'intention ou de négligence grave de sa part ou de celle de ses agents.
        Même si le Vendeur est tenu de verser une indemnité, celle-ci est
        toujours limitée au préjudice direct subi personnellement par le Client.
        Les dommages indirects ne donnent pas droit à une indemnité.
      </p>

      <h3>Article 13. Politique de confidentialité</h3>
      <p>
        <b>13.1.</b> Conformément aux dispositions du Règlement Général sur la
        Protection des Données, le Client autorise expressément le Vendeur à
        traiter ses données personnelles à des fins de gestion de la clientèle,
        de comptabilité et de marketing direct sur la base du Contrat existant
        entre eux. Si le Client souhaite s'y opposer, il suffit d'envoyer une
        lettre au Vendeur, en indiquant clairement son nom et son adresse
        complète.
        <br />
        <b>13.2.</b> Le Client peut à tout moment accéder au fichier contenant
        ses données personnelles, afin d'en prendre connaissance ou d'y apporter
        d'éventuelles corrections.
        <br />
        <b>13.3.</b> Les données personnelles ne sont jamais divulguées à des
        tiers.
      </p>

      <h3>Article 14. Avis de non-responsabilité</h3>
      <p>
        <b>14.1.</b> Le Client reconnaît par la présente que le Vendeur ne
        garantit pas la disponibilité des sites web par lesquels les biens sont
        commandés ni le contenu qui y est placé. Le Vendeur se réserve le droit
        de restreindre (même sans préavis) la disponibilité de ces pages
        internet ou l'accès à celles-ci en tout ou en partie. Le Vendeur, ses
        organes, employés ou agents d'exécution ne sont pas responsables des
        dommages pouvant résulter d'erreurs, de retards ou d'interruptions de
        transmission, en cas de défaillance d'un équipement technique ou d'un
        service, d'un contenu inexact, de la perte ou de la suppression de
        données, de virus ou de toute autre manière au cours de la commande, à
        moins que ces dommages ne soient causés délibérément ou par une
        négligence grave de la part du Vendeur.
        <br />
        <b>14.2.</b> Bien que le Vendeur apporte le plus grand soin à la
        conception et à la mise à jour de la Boutique en Ligne (
        <Link to="/" className="link">
          www.pneusfrance.fr
        </Link>
        ), celui-ci présente inévitablement des différences par rapport aux
        articles réels, notamment en ce qui concerne les couleurs. Une
        inscription dans la Boutique en Ligne ne signifie pas nécessairement que
        l'article est disponible. Le Vendeur décline toute responsabilité en cas
        d'inexactitude, d'erreur ou d'omission dans la Boutique en Ligne, sauf
        en cas de négligence grave, de faute intentionnelle ou de fraude.
        <br />
        <b>14.3.</b> Il incombe au Client de s'assurer que les marchandises
        qu'il commande répondent aux exigences du constructeur automobile. Le
        Vendeur ne sera pas responsable des dommages résultant du non-respect
        des instructions et recommandations du constructeur concernant la
        sécurité et la fiabilité du véhicule, notamment la taille des pneus et
        des jantes, la pression des pneus, ainsi que les conditions de montage
        et de stockage des pneus.
      </p>

      <h3>Article 15. Divisibilité</h3>
      <p>
        Si l'une des dispositions des présentes conditions générales devait être
        considérée comme non valide, nulle ou inapplicable, cela n'affecterait
        pas la validité, la légalité ou l'opposabilité des autres dispositions.
        Dans le cas où les parties ne parviennent pas à négocier une nouvelle
        clause valide et exécutoire, le tribunal compétent peut modérer la
        clause invalide ou inapplicable pour la ramener à ce qui est légalement
        admissible.
      </p>

      <h3>Artikel 16. Droit applicable </h3>
      <p>L'accord est régi par le droit belge.</p>
    </>
  );
}
