import React from "react";
import zomerbanden from "../img/services/zomerbanden.jpeg";
import winterbanden from "../img/services/winterbanden.png";
import fourseasons from "../img/services/4seasons.jpg";
import personenwagens from "../img/services/personenwagen.jpg";
import bestelwagens from "../img/services/van.jpeg";
import suv from "../img/services/suv.jpg";
import "./Services.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Services() {
  const { t } = useTranslation("home");

  const items = [
    {
      image: zomerbanden,
      description: t("season.summer"),
      link: "/tyres/summer",
    },
    {
      image: winterbanden,
      description: t("season.winter"),
      link: "/tyres/winter",
    },
    {
      image: fourseasons,
      description: t("season.allSeason"),
      link: "/tyres/allseason",
    },
    {
      image: personenwagens,
      description: t("season.passengerCar"),
      link: "/",
    },
    {
      image: bestelwagens,
      description: t("season.van"),
      link: "/",
    },
    {
      image: suv,
      description: t("season.SUV"),
      link: "/",
    },
  ];

  const services = items.map((service, index) => (
    <Link to={service.link} key={index} className="serviceItem">
      <img src={service.image} alt={service.description} />
      <p className="boldGreen">{service.description}</p>
    </Link>
  ));
  return (
    <>
      <div className="containerGreen">
        <h2 className="white">{t("services")}</h2>
        <div
          className="serviceDescription">
          <p>Le bon pneu pour <strong>chaque</strong> voiture en fonction de votre profil
            de conduite. Pneus d'été, pneus d'hiver ou plutôt pneus toutes saisons pour voitures,
            camionnettes et SUV ? <br />
            Vous le trouverez chez <strong>PneusFrance.fr</strong> !
          </p>,
        </div>
        <div className="flex wrap">{services}</div>
      </div>
        <div className="containerTransparant">
          <h1>Achetez des pneus enligne chez PneusFrance.fr!</h1>
          <h2 className="green mt-0">Tout ce que vous devez savoir:</h2>
          <p>
            Les bons pneus contribuent à votre sécurité sur la route. Il est donc essentiel de s'informer avant d'effectuer un achat. <br />
            Vous trouverez ici toutes les informations dont vous avez besoin pour faire le bon choix.
          </p>
          <p>
            Près de 40 marques, des marques haut de gamme aux marques à petit budget. <br />
            Tous les prix incluent la TVA et les frais d'expédition.
          </p>
          <p>
            L'outil pratique en haut de la page vous aidera à trouver les pneus les plus adaptés à votre véhicule. <br />
            Notre guide pratique des pneus vous aidera à remplir les bons paramètres. <br />
            La fonction de recherche peut être affinée par marque, saison, type de véhicule, indice de charge et de vitesse, etc. <br />
            Et si vous avez encore des questions ou si vous n'arrivez pas à trouver une solution ? <br />
            Alors pourquoi ne pas nous contacter ? Nous serons heureux de vous aider à choisir.
          </p>
          <p>
            Chez PneusFrance.fr, l'achat de pneus en ligne est un jeu d’enfant.
          </p>
          <p>
            Livré rapidement et facilement à votre domicile ou chez l'un de nos partenaires de montage. <br />
            Le choix vous appartient.
          </p>
    </div>
    </>
  );
}
