import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Brand, getBrand } from "./api/BrandApi";
import { useEffectOnce } from "react-use";
import "./BrandPage.css";
import { SearchBarSmall } from "./components/SearchBarSmall";
import { Pricing } from "./api/AuthApi";
import { getTyres, getWebPromo, SearchOptions, Tyre } from "./api/TyreApi";
import { capitalize, getCorrectPrice, isBE, isFR, isNL } from "./Utils";
import { TyreToWatch } from "./components/TyreToWatch";
import { isMobile } from "react-device-detect";
import { paginate } from "./Search";
import { Brands } from "./components/Brands";
import { useTranslation } from "react-i18next";
import { Loading } from "./Loading";
import { Helmet } from "react-helmet";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function BrandPage({
  brands,
  pricing,
  options,
  language,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
  language: String;
}) {
  const { t } = useTranslation("general");

  const [brand, setBrand] = useState<Brand | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { brandId } = useParams();

  useEffect(() => {
    const splitted = brandId.split("-");
    const actualBrandId = splitted[splitted.length - 1];
    setLoading(true);
    getBrand(actualBrandId).then(result => {
      setBrand(result);
    });
    getWebPromo().then(result => {
      setPromo(result.filter(filter => filter.brandId === actualBrandId));
    });
    getTyres(1, `?brand=${actualBrandId}`).then(r => {
      setLoading(false);
      setTyres(r.tyres);
    });
  }, [brandId]);

  const [promo, setPromo] = useState<Tyre[]>([]);
  const [tyres, setTyres] = useState<Tyre[]>([]);

  useEffectOnce(() => {
    const splitted = brandId.split("-");
    const actualBrandId = splitted[splitted.length - 1];
    getBrand(actualBrandId).then(result => {
      setBrand(result);
    });

    setLoading(true);
    getWebPromo().then(result => {
      setPromo(result.filter(filter => filter.brandId === actualBrandId));
    });
    getTyres(1, `?brand=${actualBrandId}`).then(r => {
      setLoading(false);
      setTyres(r.tyres);
    });
  });

  const [currentPagePromo, setCurrentPagePromo] = useState(1);
  const pageSize = isMobile ? 1 : 3;

  const getPageDataPromo = () => {
    const paginationData = paginate(
      promo.length > 0 ? promo : tyres,
      currentPagePromo,
      pageSize
    );
    return {
      totalCountPromo: promo.length > 0 ? promo.length : tyres.length,
      dataPromo: paginationData,
    };
  };

  const { totalCountPromo, dataPromo } = getPageDataPromo();

  const pageCountPromo = Math.ceil(totalCountPromo / pageSize);
  const nextPagePromo = (increment: number) => {
    if (
      currentPagePromo + increment <= pageCountPromo &&
      currentPagePromo + increment > 0
    ) {
      setCurrentPagePromo(currentPagePromo + increment);
    } else if (pageCountPromo === currentPagePromo) {
      setCurrentPagePromo(1);
    } else if (increment === -1 && currentPagePromo === 1) {
      setCurrentPagePromo(pageCountPromo);
    }
  };

  return (
    <>
      <Helmet>
        {getAlternateCanonical()}
        <link rel="canonical" href={window.location.href} />
        <title>{"Pneus " + brand?.name + " sur PneusFrance.fr"}</title>
        <meta
          name="description"
          content={
            "Achetez des pneus bon marché " +
            brand?.name +
            " online. PneusFrance.fr, le spécialiste des pneus de marque et de qualité pour les voitures, les camionnettes et les SUV, avec livraison GRATUITE* !"
          }
        />
        <meta
          property="og:title"
          content={"Pneus " + brand?.name + " sur PneusFrance.fr"}
        />
        <meta property="og:image" content={brand?.logo} />
        <meta
          property="og:description"
          content={
            "Achetez des pneus bon marché " +
            brand?.name +
            " online. PneusFrance.fr, le spécialiste des pneus de marque et de qualité pour les voitures, les camionnettes et les SUV, avec livraison GRATUITE* !"
          }
        />
        <meta property="og:url" content={window.location.toString()} />
      </Helmet>
      <SearchBarSmall brands={brands} options={options} />
      <div className="containerTransparant">
        <div className="breadcrumb">
          <Link to="/">{t("home")}</Link> &gt;{" "}
          <Link to="/marques-pneus">{t("brands")}</Link> &gt; {brand?.name}
        </div>
        <div className="brand-header">
          <h1 className="">
            {"Nos pneus " + (brand ? capitalize(brand.name) : "")}
          </h1>
          <div className="brandItem">
            <img
              src={brand ? brand.logo : ""}
              alt={brand ? capitalize(brand.name) : ""}
            />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : dataPromo.length > 0 ? (
          <div className="">
            <h2 className="green">
              {t("spotlight")}: {brand ? capitalize(brand.name) : ""}
            </h2>

            <div className="toWatchContainer">
              <div className="tyreToWatchContainer">
                <div className="arrow">
                  {pageCountPromo > 1 ? (
                    <div
                      className="arrow-left"
                      onClick={() => nextPagePromo(-1)}
                    />
                  ) : null}
                </div>

                {dataPromo.map((tyre, index) => {
                  const brand = brands.find(b => b.id === tyre.brandId);

                  return (
                    <TyreToWatch
                      key={index}
                      noise={tyre.noiseLevelDb}
                      noiseLevel={tyre.noiseClass}
                      height={tyre.height}
                      loadIndex={tyre.loadIndex}
                      radius={tyre.radius}
                      speedIndex={tyre.speedIndex}
                      width={tyre.width}
                      id={tyre.id}
                      title={tyre.name}
                      model={brand?.name || ""}
                      price={getCorrectPrice(tyre, pricing)}
                      car={tyre.type}
                      kind={tyre.season}
                      competitors={[]}
                      logos={[]}
                      wetRoadEfficiency={tyre.wetRoadEfficiency}
                      fuelEfficiency={tyre.fuelEfficiency}
                      brandId={tyre.brandId}
                      image={tyre.image}
                    />
                  );
                })}

                <div className="arrow">
                  {pageCountPromo > 1 ? (
                    <div
                      className="arrow-right"
                      onClick={() => nextPagePromo(1)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : tyres.length > 0 ? (
          <>
            <h1 className="green">
              {t("spotlight")}: {brand ? capitalize(brand.name) : ""}
            </h1>

            <div className="toWatchContainer">
              <div className="tyreToWatchContainer">
                <div className="arrow">
                  {pageCountPromo > 1 ? (
                    <div
                      className="arrow-left"
                      onClick={() => nextPagePromo(-1)}
                    />
                  ) : null}
                </div>

                {tyres.map((tyre, index) => {
                  return (
                    <TyreToWatch
                      key={index}
                      noise={tyre.noiseLevelDb}
                      noiseLevel={tyre.noiseClass}
                      height={tyre.height}
                      loadIndex={tyre.loadIndex}
                      radius={tyre.radius}
                      speedIndex={tyre.speedIndex}
                      width={tyre.width}
                      id={tyre.id}
                      title={tyre.name}
                      model={brand?.name || ""}
                      price={getCorrectPrice(tyre, pricing)}
                      car={tyre.type}
                      kind={tyre.season}
                      competitors={[]}
                      logos={[]}
                      wetRoadEfficiency={tyre.wetRoadEfficiency}
                      fuelEfficiency={tyre.fuelEfficiency}
                      brandId={tyre.brandId}
                      image={tyre.image}
                    />
                  );
                })}

                <div className="arrow">
                  {pageCountPromo > 1 ? (
                    <div
                      className="arrow-right"
                      onClick={() => nextPagePromo(1)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div>
          <h1 className="green">
            {brand ? "Plus d'informations sur " + capitalize(brand.name) : ""}
          </h1>
          {brand ? (
            brand.description !== "" ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: brand.description.replaceAll(
                      "{{name}}",
                      brand.name
                    ),
                  }}
                />
              </>
            ) : (
              <>
                <div
                  className="w-50"
                  dangerouslySetInnerHTML={{
                    __html: t("brandPageStandard").replaceAll(
                      "{name}",
                      brand.name
                    ),
                  }}
                />
              </>
            )
          ) : null}
        </div>
      </div>
      <Brands brands={brands} />
    </>
  );
}
