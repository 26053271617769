import React, { useState } from "react";
import { AdminHeader } from "./AdminHeader";
import {
  deleteAllTyreToWatch,
  deleteTyreToWatch,
  getTyreByEan,
  getTyresToWatch,
  saveTyreToWatch,
  TyreToWatch as Watch,
} from "./api/TyreApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { TyreToWatch } from "./components/TyreToWatch";
import { getCorrectPrice } from "./Utils";
import { uniqueId } from "./uniqueId";
import { useEffectOnce } from "react-use";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import { Helmet } from "react-helmet";
import { Select } from "./Select";

export function AdminTyres({
  brands,
  pricing,
}: {
  brands: Brand[];
  pricing: Pricing;
}) {
  const [tyresToWatch, setTyresToWatch] = useState<Watch[]>([]);
  const [tyresSavedMessage, setTyresSavedMessage] = useState<null | {
    success: boolean;
    message: string;
  }>(null);

  async function updateTyre(ean: string, oldId: string, tyreToWatchId: string) {
    const tyre = await getTyreByEan(ean);

    if (!tyre) {
      return;
    }

    setTyresToWatch(
      tyresToWatch.map(t => {
        if (t.id === oldId) {
          return {
            ...tyre,
            tyreId: tyre.id,
            id: tyre.id,
            tyreToWatchId: tyreToWatchId,
            competitorPrizes: t.competitorPrizes,
            competitorLogos: t.competitorLogos,
            country: t.country,
            descriptionFr: t.descriptionFr,
            descriptionEn: t.descriptionEn,
          };
        }

        return t;
      })
    );
  }

  useEffectOnce(() => {
    getTyresToWatch().then(result => {
      setTyresToWatch(result);
    });
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Banden in de kijker | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <div>
          <h1>Banden in de kijker</h1>
          {tyresToWatch.map((tyre, tyreIndex) => {
            const brand = brands.find(b => b.id === tyre.brandId);

            return (
              <div className="flex">
                <div>
                  <label>Band Ean</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Band ID"
                    value={tyre.ean}
                    onChange={e => {
                      setTyresToWatch(
                        tyresToWatch.map((t, i) => {
                          if (tyreIndex === i) {
                            return {
                              ...t,
                              ean: e.target.value,
                            };
                          }

                          return t;
                        })
                      );

                      if (e.target.value.length === 13) {
                        updateTyre(
                          e.target.value,
                          tyre.id,
                          tyre.tyreToWatchId
                        ).then(() => {});
                      }
                    }}
                  />
                  <Select
                    search={false}
                    title={"Land"}
                    tooltip={false}
                    options={[
                      { label: "Frankrijk", value: "fr" },
                    ]}
                    selected={tyre?.country || ""}
                    selectOption={selected => {
                      if (!tyre) {
                        return;
                      }

                      setTyresToWatch(
                        tyresToWatch.map((tyreItem, i) => {
                          if (tyreIndex === i) {
                            return {
                              ...tyre,
                              country: selected,
                            };
                          }

                          return tyreItem;
                        })
                      );
                    }}
                  />
                  <div
                    className="button w-fit"
                    onClick={async () => {
                      await deleteTyreToWatch(tyre);

                      setTyresToWatch(
                        tyresToWatch.filter((t, i) => tyreIndex !== i)
                      );
                    }}
                  >
                    Verwijderen
                  </div>
                </div>
                <div className="ml-5 flex flex-column">
                  <label>Vergelijking prijzen</label>
                  {tyre.competitorPrizes.map((prize, index) => {
                    return (
                      <div className="flex items-center">
                        <input
                          className="input"
                          type="number"
                          placeholder="Prijs"
                          min={0}
                          step={0.01}
                          value={prize}
                          onChange={e => {
                            setTyresToWatch(
                              tyresToWatch.map((tyreItem, indi) => {
                                if (tyreIndex === indi) {
                                  return {
                                    ...tyre,
                                    competitorPrizes: tyre.competitorPrizes.map(
                                      (p, i) => {
                                        if (i === index) {
                                          return parseFloat(e.target.value);
                                        }

                                        return p;
                                      }
                                    ),
                                  };
                                }

                                return tyreItem;
                              })
                            );
                          }}
                        />
                        <input
                          className="input ml-5"
                          type="text"
                          placeholder="Logo"
                          value={tyre.competitorLogos[index]}
                          onChange={e => {
                            setTyresToWatch(
                              tyresToWatch.map((tyreItem, indi) => {
                                if (tyreIndex === indi) {
                                  return {
                                    ...tyre,
                                    competitorLogos: tyre.competitorLogos.map(
                                      (p, i) => {
                                        if (i === index) {
                                          return e.target.value;
                                        }

                                        return p;
                                      }
                                    ),
                                  };
                                }

                                return tyreItem;
                              })
                            );
                          }}
                        />
                        <div
                          className="red ml-5 pointer"
                          onClick={() => {
                            setTyresToWatch(
                              tyresToWatch.map((tyreItem, indi) => {
                                if (tyreIndex === indi) {
                                  return {
                                    ...tyre,
                                    competitorPrizes: tyre.competitorPrizes.filter(
                                      (p, i) => i !== index
                                    ),
                                    competitorLogos: tyre.competitorLogos.filter(
                                      (p, i) => i !== index
                                    ),
                                  };
                                }

                                return tyreItem;
                              })
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="text-button"
                    onClick={() => {
                      setTyresToWatch(
                        tyresToWatch.map((tyreItem, indi) => {
                          if (tyreIndex === indi) {
                            return {
                              ...tyre,
                              competitorPrizes: tyre.competitorPrizes.concat(0),
                              competitorLogos: tyre.competitorLogos.concat(""),
                            };
                          }

                          return tyreItem;
                        })
                      );
                    }}
                  >
                    + Prijs toevoegen
                  </div>
                </div>
                <div className="ml-5">
                  <TyreToWatch
                    id={tyre.id}
                    height={tyre.height}
                    loadIndex={tyre.loadIndex}
                    radius={tyre.radius}
                    speedIndex={tyre.speedIndex}
                    width={tyre.width}
                    title={tyre.name}
                    model={brand?.name || ""}
                    price={getCorrectPrice(tyre, pricing)}
                    car={tyre.type}
                    kind={tyre.season}
                    competitors={tyre.competitorPrizes}
                    logos={tyre.competitorLogos}
                    wetRoadEfficiency={tyre.wetRoadEfficiency}
                    fuelEfficiency={tyre.fuelEfficiency}
                    brandId={tyre.brandId}
                    image={tyre.image}
                    noise={tyre.noiseLevelDb}
                    noiseLevel={tyre.noiseClass}
                  />
                </div>
              </div>
            );
          })}
          <div className="flex">
            <div
              className="button w-fit"
              onClick={() => {
                setTyresToWatch(
                  tyresToWatch.concat({
                    tyreId: "",
                    id: uniqueId(),
                    ean: "",
                    name: "",
                    description: "",
                    height: "",
                    width: "",
                    radius: 0,
                    season: "",
                    type: "",
                    brandId: "",
                    loadIndex: "",
                    speedIndex: "",
                    stock: 0,
                    priceBC: 0,
                    priceGarage: 0,
                    priceGarage12: 0,
                    supplierCode: "",
                    tyreToWatchId: "",
                    competitorPrizes: [],
                    competitorLogos: [""],
                    fuelEfficiency: "",
                    wetRoadEfficiency: "",
                    noiseClass: "",
                    noiseIntensity: 0,
                    noiseLevelDb: 0,
                    runFlat: false,
                    eprel: "",
                    topSale: false,
                    webPromo: false,
                    environmentCategory: "C1",
                    image: null,
                    country: "fr", // TODO: Adjust for country
                    descriptionEn: "",
                    descriptionFr: "",
                  })
                );
              }}
            >
              Nieuwe band toevoegen
            </div>
            <div
              className="button w-fit ml-5"
              onClick={async () => {
                const realTyres = tyresToWatch.filter(tyre => tyre.name);

                if (realTyres.length === 0) {
                  return;
                }

                try {
                  await deleteAllTyreToWatch();

                  for (const tyre of realTyres) {
                    await saveTyreToWatch({ ...tyre, tyreId: tyre.id });
                  }
                  setTyresSavedMessage({
                    success: true,
                    message: "Gegevens opgeslagen!",
                  });
                } catch (e) {
                  setTyresSavedMessage({
                    success: false,
                    message: "Er is iets fout gelopen",
                  });
                }

                setTimeout(() => {
                  setTyresSavedMessage(null);
                }, 1000 * 3);
              }}
            >
              Opslaan
            </div>
          </div>
          {tyresSavedMessage ? (
            <div className={`${tyresSavedMessage.success ? "" : "red"} mt-4`}>
              {tyresSavedMessage.message}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
