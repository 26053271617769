import { Tyre } from "./api/TyreApi";
import { Pricing, Token } from "./api/AuthApi";
import FuelA from "./img/fuel_efficiency_A.svg";
import FuelB from "./img/fuel_efficiency_B.svg";
import FuelC from "./img/fuel_efficiency_C.svg";
import FuelD from "./img/fuel_efficiency_D.svg";
import FuelE from "./img/fuel_efficiency_E.svg";
import WetA from "./img/wet_road_efficiency_A.svg";
import WetB from "./img/wet_road_efficiency_B.svg";
import WetC from "./img/wet_road_efficiency_C.svg";
import WetD from "./img/wet_road_efficiency_D.svg";
import WetE from "./img/wet_road_efficiency_E.svg";
import NoiseA from "./img/noise_A.png";
import NoiseB from "./img/noise_B.png";
import NoiseC from "./img/noise_C.png";
import Summer from "./img/summer.png";
import Winter from "./img/winter.png";
import Allseason from "./img/allseason.png";
import { Brand } from "./api/BrandApi";

export function getCorrectPrice(tyre: Tyre, pricing: Pricing) {
  let price = tyre.priceBC;

  if (pricing.priceType === "Garage12") {
    price = tyre.priceGarage12;
  }

  if (pricing.priceType === "Garage") {
    price = tyre.priceGarage;
  }

  let recupel = pricing.recupelC1;

  if (tyre.environmentCategory === "C2") {
    recupel = pricing.recupelC2;
  }

  return parseFloat(
    (
      ((price + pricing.transportCost + recupel) * (100 + pricing.vat)) /
      100
    ).toFixed(2)
  );
}

export function getCorrectFuelImage(index: string) {
  switch (index) {
    case "A":
      return FuelA;
    case "B":
      return FuelB;
    case "C":
      return FuelC;
    case "D":
      return FuelD;
    case "E":
      return FuelE;
  }
}

export function getCorrectWetImage(index: string) {
  switch (index) {
    case "A":
      return WetA;
    case "B":
      return WetB;
    case "C":
      return WetC;
    case "D":
      return WetD;
    case "E":
      return WetE;
  }
}

export function getCorrectNoiseImage(index: string) {
  switch (index) {
    case "A":
      return NoiseA;
    case "B":
      return NoiseB;
    case "C":
      return NoiseC;
  }
}

export function getCorrectKindImage(index: string) {
  switch (index) {
    case "Summer":
      return Summer;
    case "Winter":
      return Winter;
    case "4-Season":
      return Allseason;
  }
}

export function capitalize(item: string) {
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
}

export function parseIntOrReturnValue(item: string) {
  try {
    const result = parseFloat(item);

    if (isNaN(result)) {
      return item;
    }

    return result;
  } catch (e) {
    return item;
  }
}

export function sortValue(a: string | number, b: string | number) {
  if (typeof a === "string") {
    return 1;
  }

  if (typeof b === "string") {
    return -1;
  }

  return a - b;
}

export function getMinute(time: string) {
  return time.split(":")[1] || "";
}

export function getHour(time: string) {
  return time.split(":")[0] || "";
}

export function tokenIsExpired(token: Token) {
  if (Date.now() >= token.exp * 1000) {
    localStorage.removeItem("token");
    return true;
  }

  return false;
}

export function compareOptions(
  a: { label: string; value: string },
  b: { label: string; value: string }
) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export function compareBrands(a: Brand, b: Brand) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function getCountryParam() {
  return isBE() ? "BE" : isNL() ? "NL" : "FR";
}

export function isBE() {
  return (
    window.location.host.includes(".be")
  );
}

export function isNL() {
  return window.location.host.includes(".nl");
}

export function isFR() {

  return (
    window.location.host.includes("localhost") ||
    window.location.host.includes("pneus")
  );
}

export function isFRCountry() {
  return window.location.host.includes(".fr");
}

export function isStaging() {
  return process.env.REACT_APP_STAGING === "true";
}

export function getCountry() {
  return isBE() ? "be" : isFR() ? "fr" : "nl";
}

export const speedIndex: Record<string, string> = {
  J: "100",
  K: "110",
  L: "120",
  M: "130",
  N: "140",
  P: "150",
  Q: "160",
  R: "170",
  S: "180",
  T: "190",
  U: "200",
  H: "210",
  V: "240",
  W: "270",
  Y: "300",
  "(Y": "> 300",
  ZR: "> 300",
  Z: "> 240",
};

export function getCorrectSpeedIndex(item: string) {
  return speedIndex[item];
}

export const loadIndex: Record<string, string> = {
  62: "265",
  63: "272",
  64: "280",
  65: "290",
  66: "300",
  67: "307",
  68: "315",
  69: "325",
  70: "335",
  71: "345",
  72: "355",
  73: "365",
  74: "375",
  75: "387",
  76: "400",
  77: "412",
  78: "425",
  79: "437",
  80: "450",
  81: "462",
  82: "475",
  83: "487",
  84: "500",
  85: "515",
  86: "530",
  87: "545",
  88: "560",
  89: "580",
  90: "600",
  91: "615",
  92: "630",
  93: "650",
  94: "670",
  95: "690",
  96: "710",
  97: "730",
  98: "750",
  99: "775",
  100: "800",
  101: "825",
  102: "850",
  103: "875",
  104: "900",
  105: "925",
  106: "950",
  107: "975",
  108: "1000",
  109: "1030",
  110: "1060",
  111: "1090",
  112: "1120",
  113: "1150",
  114: "1180",
  115: "1215",
  116: "1250",
  117: "1285",
  118: "1320",
  119: "1360",
  120: "1400",
  121: "1450",
  122: "1500",
  123: "1550",
  124: "1600",
  125: "1650",
  126: "1700",
};

export function getCorrectLoadIndex(item: string) {
  const loadIndices = item.split("/");

  return loadIndices
    .map(index => {
      return loadIndex[index];
    })
    .join("/");
}

export function capitalizeEachFirstLetter(item: string) {
  return item
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}
